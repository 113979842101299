import clsx, { ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const twMerge = extendTailwindMerge({
  classGroups: {
    "font-size": [
      {
        text: [
          "2xsb",
          "xs",
          "xsb",
          "sm",
          "smb",
          "base",
          "mdb",
          "lg",
          "lgb",
          "lgbh",
          "xl",
          "2xl",
          "3xl",
          "4xl",
          "5xl",
          "6xl",
        ],
      },
    ],
  },
});

/** Merge classes with tailwind-merge with clsx full feature */
export default function clsxm(...classes: ClassValue[]) {
  return twMerge(clsx(...classes));
}

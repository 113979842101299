import { DesktopComputerIcon } from "@heroicons/react/outline";
import NoSSR from "@mpth/react-no-ssr";
import useLocalStorage from "@rehooks/local-storage";
import Button, {
  ButtonType,
} from "immigram-ui-cmon/src/components/Button/Button";
import Heading, {
  HeadingTag,
} from "immigram-ui-cmon/src/components/Heading/Heading";
import Paragraph from "immigram-ui-cmon/src/components/Paragraph/Paragraph";
import { useRouter } from "next/router";
import { FC, useEffect } from "react";

type Props = {
  ignorePath?: string;
};

const MobilePlug: FC<Props> = (props: Props) => {
  const router = useRouter();

  const [isHidden, setIsHidden] = useLocalStorage("isMobilePlugHidden", false);

  useEffect(() => {
    if (props.ignorePath && router.pathname.includes(props.ignorePath)) {
      setIsHidden(true);
    }
  }, [setIsHidden, router, props.ignorePath]);

  const handleClick = () => {
    setIsHidden(true);
  };

  return isHidden ? null : (
    <NoSSR>
      <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-white-900 px-8 text-center lg:hidden">
        <img
          className="absolute top-8 left-8 block h-12 w-auto"
          src="/images/logo/immigram-logo.png"
          alt="Immigram"
        />

        <div className="mb-0.5 flex h-24 w-24 items-center justify-center rounded-4xl bg-black-50">
          <DesktopComputerIcon className="h-10 w-10" strokeWidth={1.5} />
        </div>

        <Heading tag={HeadingTag.H4} className="mb-3">
          Open the site at a higher screen resolution
        </Heading>
        <Paragraph className="mb-8">
          This screen resolution is not currently supported, but we are working
          on it
        </Paragraph>

        <Button type={ButtonType.Primary} onClick={handleClick}>
          Continue anyway
        </Button>
      </div>
    </NoSSR>
  );
};

export default MobilePlug;

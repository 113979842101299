import clamp from "lodash/clamp";

import { ApplicantRefereeFull } from "@/lib/prisma";

import { HowLongKnowYou, RelationshipTag } from "./";

export function calculateScore(referee: ApplicantRefereeFull) {
  const featuresSum = referee.features.reduce((acc, item) => {
    acc += item?.weight ?? 0;
    return acc;
  }, 0);
  let scoringResult = ((featuresSum / 74) * 10 * 10) / 10;

  if (
    referee.relationshipTagId !== null &&
    [
      RelationshipTag.DirectManager,
      RelationshipTag.FriendAcquaintance,
    ].includes(referee.relationshipTagId)
  ) {
    scoringResult = scoringResult * 0.5;
  }

  if (referee.howLongKnowYou === HowLongKnowYou.Less12Month) {
    scoringResult = scoringResult * 0.5;
  }

  return clamp(Math.round(scoringResult), 0, 10);
}

import mapValues from "lodash/mapValues";
import mondaySdk from "monday-sdk-js";

import { MONDAY_FULL_CLIENTS } from "@/lib/integration/monday";

export type MondayItemColumnValue = {
  id: string;
  title: string;
  value: string | null;
};

export type MondayItem = {
  id: string;
  name: string;
  column_values: MondayItemColumnValue[];
};

export enum MondayColumnType {
  TEXT = "text",
  CHECK = "check",
  EMAIL = "email",
  LINK = "link",
}

export type MondayFields = Record<
  string,
  | string
  | number
  | {
      value: string | boolean | number;
      type: MondayColumnType;
    }
>;

const getMondayClient = () => {
  const token = process.env.MONDAY_API_TOKEN;
  const monday = mondaySdk();

  monday.setApiVersion("2023-10");

  if (token !== undefined) {
    monday.setToken(token);
  }
  return monday;
};

const prepareMondayColumnValues = (fields: MondayFields) => {
  return JSON.stringify(
    mapValues(fields, (field) => {
      if (typeof field !== "object") {
        return String(field);
      }

      switch (field.type) {
        case MondayColumnType.TEXT:
          return String(field.value);
        case MondayColumnType.EMAIL:
          return {
            email: String(field.value),
            text: String(field.value),
          };
        case MondayColumnType.LINK:
          return {
            url: String(field.value),
            text: String(field.value),
          };
        case MondayColumnType.CHECK:
          return field.value
            ? {
                checked: String(field.value),
              }
            : null;
      }
    })
  );
};

export const updateMondayItem = async (
  boardId: number,
  itemId: string,
  fields: MondayFields
): Promise<void> => {
  const variables = {
    boardId: boardId,
    itemId: parseInt(itemId, 10),
    columnValues: prepareMondayColumnValues(fields),
  };

  const query = `mutation change_multiple_column_values ($boardId: ID!, $itemId: ID!, $columnValues: JSON!) {
        change_multiple_column_values (
            board_id: $boardId,
            item_id: $itemId,
            column_values: $columnValues,
        )
        {
            id
        }
    }`;

  return getMondayClient()
    .api(query, { variables })
    .then((res: any) => {
      console.log("updateMondayItem", res);
    })
    .catch((err) => {
      console.error("updateMondayItemErr", err);
    });
};

export const createMondayItem = async (
  boardId: number,
  itemName: string,
  fields: MondayFields
): Promise<any> => {
  const variables = {
    boardId,
    itemName,
    columnValues: prepareMondayColumnValues(fields),
  };

  const query = `
        mutation ($boardId: ID!, $itemName: String!, $columnValues: JSON!) {
            create_item (board_id: $boardId, item_name: $itemName, column_values: $columnValues) {
                id
            }
        }
    `;

  return getMondayClient()
    .api(query, { variables })
    .then((res) => {
      console.log("createMondayItem", res.data);
      return res.data;
    })
    .catch((err) => {
      console.error("createMondayItemErr", err);
    });
};

export const getMondayItemByColumnValue = async (
  boardId: number,
  columnId: string,
  columnValue: string | null
): Promise<any> => {
  if (columnValue === null) {
    console.info(
      "mondayClientItemId is null, so skipping getMondayItemByClientId request"
    );
    return null;
  }

  const variables = {
    boardId: [boardId],
    columnId,
    columnValue: [columnValue],
  };

  const query = `
    query ($boardId: [ID!], $columnId: ID!, $columnValue: CompareValue!) {
      boards (ids: $boardId){
        items_page (limit: 1, query_params: {rules: [{column_id: $columnId, compare_value: $columnValue}]}) {
          items {
            id 
            name
            column_values {
              id
              value
            }
          }
        }
      }
    }
  `;

  return getMondayClient()
    .api(query, { variables })
    .then((res: any) => {
      return res.data?.boards?.[0]?.items_page?.items?.[0] ?? null;
    });
};

export const addUpdateToMondayItem = async (
  mondayItemId: number,
  content: string
) => {
  const variables = {
    itemId: mondayItemId,
    body: content,
  };

  const query = `mutation create_update ($itemId: ID!, $body: String!) { 
        create_update (
            item_id: $itemId,
            body: $body
        ) 
        { 
            id
        }
    }`;

  return getMondayClient()
    .api(query, { variables })
    .then((res: any) => {
      console.log(res);
    });
};

export const getMondayUsers = (): Promise<Array<any> | null> => {
  return getMondayClient()
    .api(
      `
        query {
            users {
                id
                name
                email
                title
                photo_original
                photo_thumb
                teams {
                    id
                    name
                }
            }
        }
        `
    )
    .then((res) => {
      const result = (res.data as any)?.users ? (res.data as any).users : null;
      return result;
    });
};

export const getMondayClientItemLink = (mondayFetBoardItemId: string) => {
  return `https://sterling-startups.monday.com/boards/${MONDAY_FULL_CLIENTS.ID}/pulses/${mondayFetBoardItemId}`;
};

import { ApplicantReferee, Prisma } from "@prisma/client";

import { prisma } from "@/lib/prisma";
import { calculateScore } from "@/lib/referees/utils";

export async function getRefereeByApplicationId(applicationId: string) {
  return await prisma.applicantReferee.findMany({
    where: {
      applicant: {
        Application: {
          every: {
            id: applicationId,
          },
        },
      },
    },
    orderBy: { sortOrder: Prisma.SortOrder.asc },
    include: {
      relationshipTag: true,
      signatureLikelihoodTag: true,
      features: true,
    },
  });
}

export async function getRefereeWithScoringByApplicationId(
  applicationId: string
) {
  const applicantReferees = await prisma.applicantReferee.findMany({
    where: {
      applicant: {
        Application: {
          every: {
            id: applicationId,
          },
        },
      },
    },
    orderBy: { sortOrder: Prisma.SortOrder.asc },
    include: {
      relationshipTag: true,
      signatureLikelihoodTag: true,
      features: true,
    },
  });

  // TODO: Extract to scoring fn
  const result = applicantReferees.map((x) => {
    x.scoringResult = calculateScore(x);
    return x;
  });

  return result.sort((a: ApplicantReferee, b: ApplicantReferee) => {
    const asr =
      a.scoringResultEdited !== null ? a.scoringResultEdited : a.scoringResult;
    const bsr =
      b.scoringResultEdited !== null ? b.scoringResultEdited : b.scoringResult;

    if (asr !== null && bsr !== null) {
      return asr > bsr ? -1 : asr < bsr ? 1 : 0;
    }
    return 0;
  });
}

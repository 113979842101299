import { prisma } from "@/lib/prisma";

export async function getOffers() {
  return await prisma.offer.findMany({
    where: {
      active: true,
    },
  });
}

export async function getOfferById(offerId: string, activeOnly = true) {
  return await prisma.offer.findFirst({
    where: {
      active: activeOnly ? true : undefined,
      id: offerId,
    },
  });
}

import { useCallback, useEffect, useState } from "react";

import useBooleanState from "@/lib/hook/useBooleanState";
import usePreviousValue from "@/lib/hook/usePreviousValue";

import { useModalControllerState } from "./store";
import ConfirmModal from "../ConfirmModal";

export function ModalController() {
  const [isOpen, setIsOpen] = useState(false);

  const state = useModalControllerState();
  const prevState = usePreviousValue(state);

  useEffect(() => {
    if (isOpen) {
      if (!state) {
        setIsOpen(false);
      }
    } else {
      if (state) {
        setIsOpen(true);
      }
    }
  }, [state, prevState, isOpen, setIsOpen]);

  const stateToRender = state || prevState;

  const confirmProgressState = useBooleanState(false);
  const cancelProgressState = useBooleanState(false);

  if (
    (confirmProgressState.value || cancelProgressState.value) &&
    prevState !== state
  ) {
    confirmProgressState.setFalse();
    cancelProgressState.setFalse();
  }

  const handleOnCollapse = useCallback(async () => {
    const closeModal = () => {
      stateToRender?.hideModal?.();
      stateToRender?.resolveModal?.(false);
    };

    if (stateToRender?.onCancel) {
      const hasArguments = stateToRender.onCancel.length > 0;
      const cancelPromise = stateToRender.onCancel({ closeModal });

      if (cancelPromise) {
        cancelProgressState.setTrue();
        await cancelPromise;
        cancelProgressState.setFalse();
      }

      if (!hasArguments) {
        closeModal();
      }
    } else {
      closeModal();
    }
  }, [stateToRender, cancelProgressState]);

  const handleOnConfirm = useCallback(async () => {
    const closeModal = () => {
      stateToRender?.hideModal?.();
      stateToRender?.resolveModal?.(true);
    };

    if (stateToRender?.onConfirm) {
      const hasArguments = stateToRender.onConfirm.length > 0;
      const confitmPromise = stateToRender.onConfirm({ closeModal });

      if (confitmPromise) {
        confirmProgressState.setTrue();
        await confitmPromise;
        confirmProgressState.setFalse();
      }

      if (!hasArguments) {
        closeModal();
      }
    } else {
      closeModal();
    }
  }, [stateToRender, confirmProgressState]);

  if (!stateToRender) {
    return null;
  }

  return (
    <ConfirmModal
      open={isOpen}
      onCollapse={handleOnCollapse}
      onConfirmClick={handleOnConfirm}
      gifUrl={stateToRender.gifUrl}
      gifBgPosition={stateToRender.gifBgPosition}
      heading={stateToRender.heading}
      text={stateToRender.text}
      confirmUrl={stateToRender.confirmUrl}
      cancelButtonText={stateToRender.cancelButtonText}
      iconType={stateToRender.iconType}
      confirmButtonProps={{
        progress: confirmProgressState.value,
        ...stateToRender.confirmButtonProps,
      }}
      cancelButtonProps={{
        progress: cancelProgressState.value,
        ...stateToRender.cancelButtonProps,
      }}
      confirmButtonText={stateToRender.confirmButtonText}
    />
  );
}

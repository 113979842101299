import * as React from "react";

const progressSvg = (
  <svg
    width="20"
    height="20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.206 5.5c-.479-.276-1.095-.114-1.322.39A10 10 0 1 0 6.865.504c-.524.173-.75.77-.526 1.274.225.505.815.725 1.344.565a8 8 0 1 1-5.056 4.552c.214-.509.057-1.119-.421-1.395Z"
      fill="currentColor"
    />
  </svg>
);

export default progressSvg;

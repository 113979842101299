import React from "react";
import { forwardRef } from "react";

import clsxm from "../../lib/clsxm";

export interface ParagraphProps {
  /**
   * Extra classnames
   */
  className?: string;
  /**
   * Muted styling
   */
  muted?: boolean;
  /**
   * Component children
   */
  children: React.ReactNode;
}

const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        className={clsxm(
          "mb-4 text-base text-black-900",
          props.muted && "text-black-600",
          props.className
        )}
      >
        {props.children}
      </div>
    );
  }
);

export default Paragraph;

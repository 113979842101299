import clsxm from "immigram-ui-cmon/src/lib/clsxm";
import { MouseEvent, ReactNode, useCallback } from "react";

import { IconProps } from "./index";

interface BaseIconProps extends IconProps {
  icon: ReactNode;
  freeze?: boolean;
  disabled?: boolean;
}

export function BaseIcon(props: BaseIconProps) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (!props.onClick) {
        return;
      }

      props.onClick(event);
    },
    [props]
  );

  return (
    <span
      onClick={handleClick}
      className={clsxm(
        "text-2xl leading-6",
        props.className ?? "inline-block",
        !props.onClick || props.active
          ? "opacity-100"
          : props.disabled
          ? "opacity-25"
          : "duration-default opacity-50 transition ease-in-out hover:cursor-pointer hover:opacity-100"
      )}
    >
      {props.icon}
    </span>
  );
}

import { Prisma } from "@prisma/client";

import { prisma } from "@/lib/prisma";

export async function getContestsByApplicationId(applicationId: string) {
  return await prisma.applicantContest.findMany({
    where: {
      applicant: {
        Application: {
          every: {
            id: applicationId,
          },
        },
      },
    },
    orderBy: { sortOrder: Prisma.SortOrder.asc },
  });
}

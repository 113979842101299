import { Knock } from "@knocklabs/node";

import { isServerSide } from "@/lib/app";

export enum NotificationWorkflow {
  NewReviewComment = "comments",
  ApplicationStatus = "application-status",
}

const knock = isServerSide()
  ? new Knock(process.env.KNOCK_SECRET_API_KEY)
  : null;

export default knock;

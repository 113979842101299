import knock from "@/lib/integration/knock/knock";
import { MONDAY_FULL_LEADS, MONDAY_SELF_LEADS } from "@/lib/integration/monday";
import {
  MondayColumnType,
  updateMondayItem,
} from "@/lib/integration/monday/common";
import { prisma } from "@/lib/prisma";

export function getUserById(id: string) {
  return prisma.user.findFirst({
    where: { id },
    include: { role: true },
  });
}

export function getUserByEmail(email: string) {
  return prisma.user.findFirst({
    where: { email },
    include: { role: true },
  });
}

export function updateUserLocale(id: string, locale: string) {
  return prisma.user.update({
    where: { id },
    data: { locale },
  });
}

export async function changePlatformUserEmail(
  userId: string,
  nextEmail: string
) {
  const user = await getUserById(userId);

  // Platform user email
  await prisma.user.update({
    where: { id: userId },
    data: { email: nextEmail },
  });

  // We use Knock to send emails to platform users
  await knock?.users.identify(userId, {
    email: nextEmail,
  });

  // Monday
  if (user?.isSelfServiceUser) {
    const selfMondayId = user?.mondayClientItemId || user?.mondaySelfLeadId;

    if (selfMondayId) {
      await updateMondayItem(MONDAY_SELF_LEADS.ID, selfMondayId, {
        [MONDAY_SELF_LEADS.COLS.EMAIL]: {
          value: nextEmail,
          type: MondayColumnType.EMAIL,
        },
      });
    }
  } else {
    if (user?.mondayClientItemId) {
      await updateMondayItem(MONDAY_FULL_LEADS.ID, user?.mondayClientItemId, {
        [MONDAY_FULL_LEADS.COLS.EMAIL]: {
          value: nextEmail,
          type: MondayColumnType.EMAIL,
        },
      });
    }
  }
}

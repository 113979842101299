import { Dialog, Transition } from "@headlessui/react";
import { Fragment, memo, ReactNode, useEffect, useRef } from "react";

import { Icon24Cancel } from "../Icons";
import clsxm from "../../lib/clsxm";

export enum ModalOverlayStyle {
  Default = "default",
  Blur = "blur",
}

export interface ModalProps {
  children: ReactNode;
  isOpened: boolean;
  fullScreen?: boolean;
  panelClassName?: string;
  overlayStyle?: ModalOverlayStyle;
  onClose?: () => void;
  hideCloseButton?: boolean | undefined;
}

const Modal = (props: ModalProps) => {
  const { overlayStyle = ModalOverlayStyle.Default } = props;
  const divRef = useRef(null); // We don't want an initiall focus on some element inside the modal

  useEffect(() => {
    if (!props.isOpened) {
      // Fix scroll when programmatically closing the modal
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    }
  }, [props.isOpened]);

  return (
    <>
      <Transition.Root show={props.isOpened} as={Fragment}>
        <Dialog
          initialFocus={divRef}
          open={props.isOpened}
          as="div"
          className="relative z-50"
          onClose={() => props.onClose && props.onClose()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsxm(
                "fixed inset-0 transition-opacity",
                overlayStyle === ModalOverlayStyle.Default && "bg-black-700",
                overlayStyle === ModalOverlayStyle.Blur && "backdrop-blur-md"
              )}
            />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-24 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-24 sm:scale-95"
              >
                <Dialog.Panel
                  className={clsxm(
                    "flex w-full transform flex-col items-center justify-center rounded-4xl bg-white-900 shadow-lg transition-all sm:my-8 sm:w-[496px]",
                    props.fullScreen &&
                      "fixed top-12 left-0 right-0 bottom-0 !my-0 items-start justify-start rounded-t-4xl rounded-b-none sm:w-full",
                    props.panelClassName
                  )}
                >
                  <button className="opacity-0" ref={divRef} />
                  <div className="relative h-full w-full overflow-y-auto px-4 sm:px-0">
                    {props.children}
                  </div>
                  {!props.hideCloseButton &&
                    !props.fullScreen &&
                    props.onClose && (
                      <div
                        className="absolute top-0 -right-16 z-20 flex h-11 w-11 cursor-pointer items-center justify-center rounded-2xl bg-white-900 text-black-900 transition hover:bg-white-700 hover:text-black-700"
                        onClick={props.onClose}
                      >
                        <Icon24Cancel />
                      </div>
                    )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>

          {props.fullScreen && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-24 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-24 sm:scale-95"
            >
              <div
                className="fixed top-[4.25rem] right-5 z-50 flex h-11 w-11 cursor-pointer items-center justify-center rounded-2xl bg-black-50 text-black-900 transition hover:bg-black-100 hover:text-black-700"
                onClick={props.onClose}
              >
                <Icon24Cancel />
              </div>
            </Transition.Child>
          )}
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default memo(Modal);

import { OfferProduct } from "@/lib/offer";
import { prisma } from "@/lib/prisma";

export const getApplicationPaymentsByProduct = async (
  applicationId: string,
  offerProduct: OfferProduct
) => {
  return await prisma.applicationPayment.findMany({
    where: {
      applicationId,
      offer: {
        product: offerProduct,
      },
    },
    include: {
      offer: true,
    },
  });
};

export async function setApplicationIsSelfServicePurchased(
  applicationId: string,
  status: boolean
) {
  await prisma.application.update({
    where: {
      id: applicationId,
    },
    data: {
      isSelfServicePurchased: status,
    },
  });
}

import {
  ApplicantAnotherExperience,
  ApplicantArticle,
  ApplicantAward,
  ApplicantCommunity,
  ApplicantCompany,
  ApplicantContest,
  ApplicantEntrepreneurship,
  ApplicantMentionInMedia,
  ApplicantMentorship,
  ApplicantOpenSource,
  ApplicantPatent,
  ApplicantPublicTalk,
  ApplicantScientificWork,
  Prisma,
  PrismaClient,
} from "@prisma/client";

import { isServerSide } from "@/lib/app";

// PrismaClient is attached to the `global` object in development to prevent
// exhausting your database connection limit.
//
// Learn more:
// https://pris.ly/d/help/next-js-best-practices

declare global {
  // allow global `var` declarations
  /* eslint no-var: off */
  var prisma: PrismaClient | undefined;
}

let prisma: PrismaClient;

if (isServerSide()) {
  if (process.env.NODE_ENV === "production") {
    prisma = new PrismaClient();
  } else {
    if (!global.prisma) {
      global.prisma = new PrismaClient();
    }
    prisma = global.prisma;
  }
}

/***********************************/
/* SOFT DELETE MIDDLEWARE */
/***********************************/

// prisma.$use(async (params, next) => {
//     if (params.model === Prisma.ModelName.User) {
//         if (params.action === "findUnique" || params.action === "findFirst") {
//             // Change to findFirst - you cannot filter
//             // by anything except ID / unique with findUnique
//             params.action = "findFirst";
//             // Add "deleted" filter
//             // ID filter maintained
//             if (params && params.args && params.args.where) {
//                 params.args.where["deleted"] = null;
//             }
//         }
//         if (params.action === "findMany") {
//             // Find many queries
//             if (params.args) {
//                 if (params.args.where) {
//                     if (params.args.where.deleted == undefined) {
//                         // Exclude deleted records if they have not been explicitly requested
//                         params.args.where["deleted"] = null;
//                     }
//                 } else {
//                     params.args["where"] = { deleted: null };
//                 }
//             }
//         }
//     }
//     return next(params);
// });

// prisma.$use(async (params, next) => {
//     if (params.model === Prisma.ModelName.User) {
//         if (params.action == "update") {
//             // Change to updateMany - you cannot filter
//             // by anything except ID / unique with findUnique
//             params.action = "updateMany";
//             // Add "deleted" filter
//             // ID filter maintained
//             params.args.where["deleted"] = null;
//         }
//         if (params.action === "updateMany") {
//             if (params.args.where != undefined) {
//                 params.args.where["deleted"] = null;
//             } else {
//                 params.args["where"] = { deleted: null };
//             }
//         }
//     }
//     return next(params);
// });

// prisma.$use(async (params, next) => {
//     // Check incoming query type
//     if (params.model === Prisma.ModelName.User) {
//         if (params.action == "delete") {
//             // Delete queries
//             // Change action to an update
//             params.action = "update";
//             params.args["data"] = { deleted: new Date() };
//         }
//         if (params.action === "deleteMany") {
//             // Delete many queries
//             params.action = "updateMany";
//             if (params.args.data != undefined) {
//                 params.args.data["deleted"] = new Date();
//             } else {
//                 params.args["data"] = { deleted: new Date() };
//             }
//         }
//     }
//     return next(params);
// });

export type ApplicationFull = Prisma.ApplicationGetPayload<{
  include: {
    applicant: {
      include: {
        user: true;
      };
    };
    applicationSection: true;
    applicationForm: {
      include: {
        children: {
          include: {
            children: true;
          };
        };
      };
    };
  };
}>;

export type ApplicationSectionFull = Prisma.ApplicationSectionGetPayload<{
  include: {
    application: {
      include: {
        applicant: {
          include: {
            user: true;
          };
        };
      };
    };
  };
}>;

export type ApplicationFormFull = Prisma.ApplicationFormGetPayload<{
  include: {
    fields: true;
    children: {
      include: {
        fields: true;
        children: {
          include: {
            fields: true;
          };
        };
      };
    };
  };
}>;

export type ApplicationFormPrepared = Prisma.ApplicationFormGetPayload<{
  include: { fields: true };
}> & {
  fieldsMap: Record<string, ApplicationFormFull["fields"][number]>;
  children?: ApplicationFormPrepared[];
};

export type ApplicantRefereeFull = Prisma.ApplicantRefereeGetPayload<{
  include: {
    relationshipTag: true;
    signatureLikelihoodTag: true;
    features: true;
  };
}>;

export type ApplicantCompanyFull = Prisma.ApplicantCompanyGetPayload<{
  include: {
    careerRole: true;
    applicantProjectList: {
      include: {
        projectRole: true;
      };
    };
  };
}>;

export type ApplicantEntrepreneurshipFull =
  Prisma.ApplicantEntrepreneurshipGetPayload<{
    include: {
      applicantMilestoneList: true;
    };
  }>;

export type ApplicantProjectFull = Prisma.ApplicantProjectGetPayload<{
  include: {
    projectRole: true;
    applicantCompany: {
      include: {
        careerRole: true;
      };
    };
  };
}>;

export type ApplicantMilestoneFull = Prisma.ApplicantMilestoneGetPayload<{
  include: {
    applicantEntrepreneurship: true;
  };
}>;

export type ApplicantCompanyList = {
  data: ApplicantCompanyFull[] | ApplicantCompany[];
};

export type ApplicantProjectList = {
  data: ApplicantProjectFull[];
};

export type ApplicantEntrepreneurshipList = {
  data: ApplicantEntrepreneurship[];
};

export type ApplicantMilestoneList = {
  data: ApplicantMilestoneFull[];
};

export type ApplicantRefereeList = {
  data: ApplicantRefereeFull[];
};

export type ApplicantArticleList = {
  data: ApplicantArticle[];
};

export type ApplicantMentionInMediaList = {
  data: ApplicantMentionInMedia[];
};

export type ApplicantPublicTalkList = {
  data: ApplicantPublicTalk[];
};

export type ApplicantContestList = {
  data: ApplicantContest[];
};

export type ApplicantAwardList = {
  data: ApplicantAward[];
};

export type ApplicantPatentList = {
  data: ApplicantPatent[];
};

export type ApplicantOpenSourceList = {
  data: ApplicantOpenSource[];
};

export type ApplicantMentorshipList = {
  data: ApplicantMentorship[];
};

export type ApplicantScientificWorkList = {
  data: ApplicantScientificWork[];
};

export type ApplicantCommunityList = {
  data: ApplicantCommunity[];
};

export type ApplicantAnotherExperienceList = {
  data: ApplicantAnotherExperience[];
};

export { prisma };

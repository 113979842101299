import { useCallback, useMemo, useState } from "react";

export default function useBooleanState(initial = false) {
  const [value, setValue] = useState(initial);
  const setTrue = useCallback(() => setValue(true), [setValue]);
  const setFalse = useCallback(() => setValue(false), [setValue]);
  const toggle = useCallback(() => setValue(!value), [setValue, value]);

  return useMemo(
    () => ({ value, setTrue, setFalse, toggle, setValue }),
    [value, setTrue, setFalse, toggle, setValue]
  );
}

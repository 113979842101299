import {
  ApplicantArticle,
  ApplicantAward,
  ApplicantCommunity,
  ApplicantContest,
  ApplicantMentionInMedia,
  ApplicantMentorship,
  ApplicantOpenSource,
  ApplicantPatent,
  ApplicantPublicTalk,
  ApplicantScientificWork,
} from "@prisma/client";
import sum from "lodash/sum";

import { getArticlesByApplicationId } from "./article";
import { getAwardsByApplicationId } from "./award";
import { getCommunitiesByApplicationId } from "./community";
import { getContestsByApplicationId } from "./contest";
import { getMentionInMediaByApplicationId } from "./mentions-in-media";
import { getMentorshipsApplicationId } from "./mentorship";
import { getOpenSourceByApplicationId } from "./open-source";
import { getPatentsApplicationId } from "./patent";
import { getPublicTalksByApplicationId } from "./public-talk";
import { getScientificWorkByApplicationId } from "./scientific-work";

export async function calculateScoreByApplicationId(applicationId: string) {
  const [
    articles,
    awards,
    communities,
    contests,
    mentionsInMedia,
    mentorships,
    openSource,
    patents,
    publicTalks,
    scientificWork,
  ] = await Promise.all([
    getArticlesByApplicationId(applicationId),
    getAwardsByApplicationId(applicationId),
    getCommunitiesByApplicationId(applicationId),
    getContestsByApplicationId(applicationId),
    getMentionInMediaByApplicationId(applicationId),
    getMentorshipsApplicationId(applicationId),
    getOpenSourceByApplicationId(applicationId),
    getPatentsApplicationId(applicationId),
    getPublicTalksByApplicationId(applicationId),
    getScientificWorkByApplicationId(applicationId),
  ]);

  const scores = [
    calculateArticleScore(articles),
    calculateAwardScore(awards),
    calculateCommunityScore(communities),
    calculateContestScore(contests),
    calculateMentionsInMediacore(mentionsInMedia),
    calculateMentorshipScore(mentorships),
    calculateOpenSourceScore(openSource),
    calculatePatentScore(patents),
    calculatePublicTalkScore(publicTalks),
    calculateScientificWorkScore(scientificWork),
  ];

  const isDiverseActivities = scores.filter((score) => score > 0).length;

  return isDiverseActivities ? sum(scores) * 1.25 : sum(scores);
}

function calculateArticleScore(items: ApplicantArticle[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 3.1;

    if (item.numbersOfAttendee) {
      const views = parseInt(item.numbersOfAttendee, 10);
      if (Number.isFinite(views)) {
        if (views < 200) {
          itemScore = itemScore * 1;
        }
        if (views >= 200 && views < 3000) {
          itemScore = itemScore * 2;
        }
        if (views >= 3000) {
          itemScore = itemScore * 3;
        }
      }
    }

    if (item.mediaRecognition) {
      const mediaRecognitionList = item.mediaRecognition.split(",");
      if (mediaRecognitionList.includes("blog_no_moderation")) {
        itemScore = itemScore * 0.7;
      }
      if (mediaRecognitionList.includes("not_well_known")) {
        itemScore = itemScore * 1;
      }
      if (mediaRecognitionList.includes("prestigious_platform")) {
        itemScore = itemScore * 1.2;
      }
    }

    if (!item.proof?.split(",").filter(Boolean).length) {
      itemScore = itemScore * 0;
    }

    if (item.year) {
      if (new Date().getFullYear() - item.year > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

function calculateAwardScore(items: ApplicantAward[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 5;

    if (item.recognitionLevel) {
      if (item.recognitionLevel === "local") {
        itemScore = itemScore * 0;
      }
      if (item.recognitionLevel === "regional") {
        itemScore = itemScore * 1;
      }
      if (item.recognitionLevel === "state_level") {
        itemScore = itemScore * 1.5;
      }
      if (item.recognitionLevel === "international") {
        itemScore = itemScore * 2;
      }
    }

    if (item.role === "participant") {
      if (item.place === "other") {
        itemScore = itemScore * 0.7;
      }
      if (item.place === "top20") {
        itemScore = itemScore * 1;
      }
      if (item.place === "top10") {
        itemScore = itemScore * 1.2;
      }
      if (item.place === "top5") {
        itemScore = itemScore * 1.5;
      }
    }
    if (item.role === "organiser") {
      itemScore = itemScore * 1.2;
    }
    if (item.role === "jury") {
      itemScore = itemScore * 1.5;
    }

    if (!item.proof?.split(",").filter(Boolean).length) {
      itemScore = itemScore * 0;
    }

    if (item.year) {
      if (new Date().getFullYear() - item.year > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

function calculateCommunityScore(items: ApplicantCommunity[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 3.6;

    if (item.numbersOfAttendee) {
      const members = parseInt(item.numbersOfAttendee, 10);

      if (Number.isFinite(members)) {
        if (members < 30) {
          itemScore = itemScore * 1;
        }
        if (members > 30 && members < 100) {
          itemScore = itemScore * 1;
        }
        if (members >= 100) {
          itemScore = itemScore * 1;
        }
      }
    }

    if (item.recognitionLevel) {
      if (item.recognitionLevel === "local") {
        itemScore = itemScore * 0.7;
      }
      if (item.recognitionLevel === "national") {
        itemScore = itemScore * 1;
      }
      if (item.recognitionLevel === "international") {
        itemScore = itemScore * 1.2;
      }
    }

    if (!item.proof?.split(",").filter(Boolean).length) {
      itemScore = itemScore * 0;
    }

    if (item.toYear) {
      if (new Date().getFullYear() - item.toYear > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

function calculateContestScore(items: ApplicantContest[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 5;

    if (item.recognitionLevel) {
      if (item.recognitionLevel === "local") {
        itemScore = itemScore * 0;
      }
      if (item.recognitionLevel === "regional") {
        itemScore = itemScore * 1;
      }
      if (item.recognitionLevel === "state_level") {
        itemScore = itemScore * 1.5;
      }
      if (item.recognitionLevel === "international") {
        itemScore = itemScore * 2;
      }
    }

    if (item.role === "participant") {
      if (item.place === "other") {
        itemScore = itemScore * 0.7;
      }
      if (item.place === "top20") {
        itemScore = itemScore * 1;
      }
      if (item.place === "top10") {
        itemScore = itemScore * 1.2;
      }
      if (item.place === "top5") {
        itemScore = itemScore * 1.5;
      }
    }
    if (item.role === "organiser") {
      itemScore = itemScore * 1.2;
    }
    if (item.role === "jury") {
      itemScore = itemScore * 1.5;
    }

    if (!item.proof?.split(",").filter(Boolean).length) {
      itemScore = itemScore * 0;
    }

    if (item.year) {
      if (new Date().getFullYear() - item.year > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

function calculateMentionsInMediacore(items: ApplicantMentionInMedia[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 1.2;

    if (item.numbersOfAttendee) {
      const views = parseInt(item.numbersOfAttendee, 10);
      if (Number.isFinite(views)) {
        if (views < 200) {
          itemScore = itemScore * 1;
        }
        if (views > 200 && views < 3000) {
          itemScore = itemScore * 2;
        }
        if (views >= 3000) {
          itemScore = itemScore * 3;
        }
      }
    }

    if (item.mediaRecognition) {
      const mediaRecognitionList = item.mediaRecognition.split(",");
      if (
        mediaRecognitionList.some((item: string) =>
          ["blog_no_moderation", "youtube", "news_agency"].includes(item)
        )
      ) {
        itemScore = itemScore * 0.7;
      }
      if (mediaRecognitionList.includes("not_well_known")) {
        itemScore = itemScore * 1;
      }
      if (mediaRecognitionList.includes("prestigious_platform")) {
        itemScore = itemScore * 1.2;
      }
    }

    if (!item.proof?.split(",").filter(Boolean).length) {
      itemScore = itemScore * 0;
    }

    if (item.year) {
      if (new Date().getFullYear() - item.year > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

function calculateMentorshipScore(items: ApplicantMentorship[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 3.6;

    if (item.recognitionLevel) {
      if (item.recognitionLevel === "locally_institution") {
        itemScore = itemScore * 1;
      }
      if (item.recognitionLevel === "nationally_institution") {
        itemScore = itemScore * 2;
      }
      if (item.recognitionLevel === "globally_institution") {
        itemScore = itemScore * 3;
      }
    }

    if (item.studentsNumbers) {
      const students = parseInt(item.studentsNumbers, 10);
      if (Number.isFinite(students)) {
        if (students < 50) {
          itemScore = itemScore * 0.7;
        }
        if (students > 50 && students < 100) {
          itemScore = itemScore * 1;
        }
        if (students >= 100) {
          itemScore = itemScore * 1.2;
        }
      }
    }

    if (!item.proof?.split(",").filter(Boolean).length) {
      itemScore = itemScore * 0;
    }

    if (item.toYear) {
      if (new Date().getFullYear() - item.toYear > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

function calculateOpenSourceScore(items: ApplicantOpenSource[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 1.6;

    if (item.significantStats) {
      const stats = parseInt(item.significantStats, 10);

      if (stats < 30) {
        itemScore = itemScore * 1;
      }
      if (stats > 30 && stats < 100) {
        itemScore = itemScore * 1.5;
      }
      if (stats >= 100) {
        itemScore = itemScore * 2;
      }
    }

    if (item.toYear) {
      if (new Date().getFullYear() - item.toYear > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

function calculatePatentScore(items: ApplicantPatent[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 11;

    if (!item.proof?.split(",").filter(Boolean).length) {
      itemScore = itemScore * 0;
    }

    if (item.year) {
      if (new Date().getFullYear() - item.year > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

function calculatePublicTalkScore(items: ApplicantPublicTalk[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 3.6;

    if (item.numbersOfAttendee) {
      const attendies = parseInt(item.numbersOfAttendee, 10);
      if (Number.isFinite(attendies)) {
        if (attendies < 30) {
          itemScore = itemScore * 1;
        }
        if (attendies > 30 && attendies < 100) {
          itemScore = itemScore * 2;
        }
        if (attendies >= 100) {
          itemScore = itemScore * 3;
        }
      }
    }

    if (item.recognitionLevel) {
      if (item.recognitionLevel === "local") {
        itemScore = itemScore * 0.7;
      }
      if (item.recognitionLevel === "national") {
        itemScore = itemScore * 1;
      }
      if (item.recognitionLevel === "international") {
        itemScore = itemScore * 1.2;
      }
    }

    if (item.role) {
      if (item.role === "other") {
        itemScore = itemScore * 1;
      }
      if (item.role === "organiser") {
        itemScore = itemScore * 1.2;
      }
      if (item.role === "main_speaker") {
        itemScore = itemScore * 1.5;
      }
    }

    if (!item.proof?.split(",").filter(Boolean).length) {
      itemScore = itemScore * 0;
    }

    if (item.year) {
      if (new Date().getFullYear() - item.year > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

function calculateScientificWorkScore(items: ApplicantScientificWork[] = []) {
  return items.reduce<number>((acc, item) => {
    let itemScore = 3.6;

    if (item.recognitionLevel) {
      if (item.recognitionLevel === "locally_institution") {
        itemScore = itemScore * 1;
      }
      if (item.recognitionLevel === "nationally_institution") {
        itemScore = itemScore * 2;
      }
      if (item.recognitionLevel === "globally_institution") {
        itemScore = itemScore * 3;
      }
    }

    if (!item.proof?.split(",").filter(Boolean).length) {
      itemScore = itemScore * 0;
    }

    if (item.toYear) {
      if (new Date().getFullYear() - item.toYear > 5) {
        itemScore = itemScore * 0;
      }
    }

    return acc + itemScore;
  }, 0);
}

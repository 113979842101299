import mixpanel, { Config, OverridedMixpanel } from "mixpanel-browser";
import React, { createContext, ReactNode, useContext } from "react";

const context = createContext<OverridedMixpanel | null>(null);
const Provider = context.Provider;
export const useMixpanel = () => useContext(context);

const defaults = {
  track_pageview: false, // Rarely makes sense to track page views in React apps
};

export const MixpanelProvider = ({
  children,
  token,
  config,
  name,
}: {
  children: ReactNode;
  token?: string;
  config?: Partial<Config>;
  name?: string;
}) => {
  config = Object.assign({}, defaults, config);

  if (token !== undefined) {
    if (name !== undefined) {
      mixpanel.init(token, config, name);
    } else {
      mixpanel.init(token, config);
    }
  }

  return <Provider value={mixpanel}>{children}</Provider>;
};

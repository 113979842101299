import React, { forwardRef, ReactNode } from "react";

import clsxm from "../../lib/clsxm";

interface HeadingProps {
  /**
   * HTML Tag
   */
  tag: string;
  /**
   * Extra class names
   */
  className?: string;
  /**
   * Component children
   */
  children?: ReactNode;
}

export enum HeadingTag {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
  H7 = "h7",
}

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ tag = HeadingTag.H1, className, children }, ref) => {
    let resultClassName = className;
    let resultTag = tag;

    switch (tag) {
      case HeadingTag.H1:
        resultClassName = "text-6xl";
        break;
      case HeadingTag.H2:
        resultClassName = "text-5xl";
        break;
      case HeadingTag.H3:
        resultClassName = "text-4xl";
        break;
      case HeadingTag.H4:
        resultClassName = "text-3xl";
        break;
      case HeadingTag.H5:
        resultClassName = "text-2xl";
        break;
      case HeadingTag.H6:
        resultClassName = "text-xl";
        break;
      case HeadingTag.H7:
        resultClassName = "text-lgbh";
        resultTag = "div";
        break;
      default:
        resultClassName = "";
        break;
    }
    const component = React.createElement(
      resultTag,
      {
        className: clsxm(
          "block my-6 text-black-900 font-granate",
          resultClassName,
          className
        ),
        ref,
      },
      children
    );

    return component;
  }
);

export default Heading;

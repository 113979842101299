import { User } from "@prisma/client";
import Gleap from "gleap";
import Notification from "immigram-ui-cmon/src/components/Notification/Notification";
import NextApp, { AppContext, AppInitialProps, AppProps } from "next/app";
import Head from "next/head";
import { getSession } from "next-auth/react";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import { useEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-popper-tooltip/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "@knocklabs/react-notification-feed/dist/index.css";
import "@/styles/globals.css";
import "@/styles/index.css";
import { isServerSide } from "@/lib/app";
import { UserContext } from "@/lib/contexts/userContext";
import { MixpanelProvider } from "@/lib/mixpanel";
import MobilePlug from "@/components/MobilePlug/MobilePlug";
import { ModalController } from "@/components/Modal/ModalController";
import { QueryClientProvider } from "@/core/client/context/query-client";
import { getApplicationById, getUserById } from "@/core/server";
import nextI18nextConfig from "../../next-i18next.config";
interface ImmigramAppProps {
  ownUser: User | null;
  applicationUser: User | null;
}
const App = ({
  Component,
  pageProps: {
    session,
    ...pageProps
  },
  ...props
}: AppProps & ImmigramAppProps) => {
  useEffect(() => {
    const user = props.applicationUser;
    if (!user || !user.useCookies) {
      return;
    }
    Gleap.initialize("zToTtIqK2EPgnXqw7orcd6VBijvyAGso");
  }, [props.applicationUser]);
  return <>
      <Head>
        <title>Immigram Platform: Global Talent Visa UK</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#111111" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <UserContext.Provider value={{
      ownUser: props.ownUser,
      applicationUser: props.applicationUser
    }}>
        <SessionProvider session={session}>
          <QueryClientProvider>
            <MixpanelProvider token={process.env.NEXT_PUBLIC_MIXPANEL_TOKEN} config={{
            debug: true,
            disable_cookie: !props.ownUser?.useAnalyticsCookies
          }}>
              <SkeletonTheme baseColor="#F4F7FA" highlightColor="#FBFBFB" borderRadius="16px">
                <Component {...pageProps} />
                <MobilePlug ignorePath="/signin" />
                <Notification />
                <ModalController />
              </SkeletonTheme>
            </MixpanelProvider>
          </QueryClientProvider>
        </SessionProvider>
      </UserContext.Provider>
    </>;
};
App.getInitialProps = async function getInitialProps(context: AppContext) {
  const {
    applicationId
  } = context?.router?.query || {};
  const session = await getSession(context);
  const props: AppInitialProps & ImmigramAppProps = {
    ...(await NextApp.getInitialProps(context)),
    ownUser: null,
    applicationUser: null
  };
  if (session?.user) {
    props.ownUser = await getUserById(session.user.id);
  }
  if (applicationId && isServerSide()) {
    const application = await getApplicationById(applicationId);
    props.applicationUser = application?.applicant?.user || null;
  }
  return props;
};
const AppWithTranslation = appWithTranslation(App, nextI18nextConfig);
export default AppWithTranslation;
import { Prisma } from "@prisma/client";

import { prisma } from "@/lib/prisma";

export async function getMilestonesByApplicationId(applicationId: string) {
  return await prisma.applicantMilestone.findMany({
    where: {
      applicantEntrepreneurship: {
        applicant: {
          Application: {
            every: {
              id: applicationId,
            },
          },
        },
      },
    },
    include: {
      applicantEntrepreneurship: true,
    },
    orderBy: {
      entrepreneurshipSortOrder: Prisma.SortOrder.asc,
    },
  });
}

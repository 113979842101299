export enum HowLongKnowYou {
  Less12Month = "less_12_month",
  More12Month = "more_12_month",
}

export enum RelationshipTag {
  SkipManager = 1,
  DirectManager = 2,
  CLevel = 3,
  Colleague = 4,
  FriendAcquaintance = 5,
  Client = 6,
  Investor = 7,
}

export enum RefereeSize {
  Collapsed = "collapsed",
  Standard = "standard",
  Detailed = "detailed",
}

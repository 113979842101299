import { prisma } from "@/lib/prisma";

export async function getApplicationById(applicationId?: string) {
  if (!applicationId) return null;
  return await prisma.application.findFirst({
    where: {
      id: applicationId,
    },
    include: {
      applicant: {
        include: {
          user: true,
        },
      },
      applicationSection: true,
      applicationForm: {
        where: {
          parentId: null,
        },
        include: {
          children: {
            orderBy: {
              created: "desc",
            },
            include: {
              children: {
                orderBy: {
                  created: "desc",
                },
              },
            },
          },
        },
      },
      visaType: true,
    },
  });
}

export async function getApplicationByUserId(userId: string) {
  return await prisma.application.findFirst({
    where: {
      applicant: {
        user: {
          id: userId,
        },
      },
    },
    include: {
      applicant: {
        include: {
          user: true,
        },
      },
      applicationSection: true,
    },
  });
}

import progressSvg from "immigram-ui-cmon/src/svg/progress";
import Link from "next/link";
import * as React from "react";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import clsxm from "../../lib/clsxm";

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Flat = "flat",
  PrimaryInverse = "primaryInverse",
  SecondaryInverse = "secondaryInverse",
  FlatInverse = "flatInverse",
}

export interface ButtonProps {
  className?: string;
  buttonClassName?: string;
  children?: ReactNode;
  icon?: ReactNode;
  type?: ButtonType;
  progress?: boolean;
  hiddenProgress?: boolean;
  size?: "250" | "200";
  action?: "button" | "submit";
  disabled?: boolean;
  tabIndex?: number;
  href?: string;
  target?: React.ComponentProps<typeof Link>["target"];
  rel?: React.ComponentProps<typeof Link>["rel"];
  onClick?: (event: React.MouseEvent) => void;
}

const Button = React.forwardRef(
  (
    {
      className,
      buttonClassName,
      children,
      icon,
      type = ButtonType.Primary,
      size = "250",
      action = "button",
      disabled = false,
      tabIndex,
      progress,
      hiddenProgress,
      onClick,
      href,
      target,
      rel,
    }: ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    let classes: string[] = [];
    let textClasses: string[] = [];

    const [showHiddenProgress, setShowHiddenProgress] = useState(false);
    const fireOnClickRef = useRef<React.MouseEvent | null>(null);

    useEffect(() => {
      if (!hiddenProgress) {
        if (showHiddenProgress) {
          setShowHiddenProgress(false);
        }

        if (fireOnClickRef.current) {
          if (!disabled) {
            onClick?.(fireOnClickRef.current);
          }

          fireOnClickRef.current = null;
        }
      }
    }, [hiddenProgress, onClick, disabled, showHiddenProgress]);

    const handleOnClick = useCallback(
      (e: React.MouseEvent) => {
        if (hiddenProgress) {
          setShowHiddenProgress(true);
          fireOnClickRef.current = e;
          return;
        }

        if (!progress && !hiddenProgress && !disabled) {
          onClick?.(e);
        }
      },
      [progress, hiddenProgress, onClick, disabled]
    );

    const showProgress = progress || showHiddenProgress;

    switch (size) {
      case "250":
        classes = [
          ...classes,
          "text-mdb rounded-2xl",
          icon && !children ? "p-2.5" : "pl-4 pr-4 pt-2.5 pb-2.5 gap-2",
        ];
        textClasses = [...textClasses, "pb-1t"];
        break;
      case "200":
        classes = [
          ...classes,
          "text-mdb rounded-xl",
          icon && !children ? "p-1" : "pr-3 pt-2t pb-2t gap-1",
          icon && children ? "pl-2" : icon ? "pl-1" : "pl-3",
        ];
        textClasses = [...textClasses, "pb-0.5"];
        break;
      default:
        throw new Error("Unsupported button size");
    }
    switch (type) {
      case ButtonType.Primary:
        classes = [
          ...classes,
          "bg-black-900 text-white-900",
          "hover:bg-black-600",
          "active:bg-black-500",
          "disabled:bg-black-300 disabled:text-white-500",
        ];
        break;
      case ButtonType.Secondary:
        classes = [
          ...classes,
          "bg-black-50 text-black-900",
          "hover:bg-black-200",
          "active:bg-black-300",
          "disabled:bg-black-50 disabled:text-black-500",
        ];
        break;
      case ButtonType.Flat:
        classes = [
          ...classes,
          "bg-transparent text-black-900",
          "hover:bg-black-100",
          "active:bg-black-200",
          "disabled:bg-transparent disabled:text-black-500",
        ];
        break;
      case ButtonType.PrimaryInverse:
        classes = [
          ...classes,
          "bg-white-900 text-black-900",
          "hover:bg-white-600",
          "active:bg-white-500",
          "disabled:bg-white-300 disabled:text-white-600",
        ];
        break;
      case ButtonType.SecondaryInverse:
        classes = [
          ...classes,
          "bg-white-50 text-white-900",
          "hover:bg-white-200",
          "active:bg-white-300",
          "disabled:bg-white-50 disabled:text-white-500",
        ];
        break;
      case ButtonType.FlatInverse:
        classes = [
          ...classes,
          "bg-transparent text-white-900",
          "hover:bg-white-100",
          "active:bg-white-200",
          "disabled:bg-transparent disabled:text-white-500",
        ];
        break;
    }

    const button = (
      <button
        ref={ref}
        type={action}
        className={
          clsxm(
            classes,
            "relative",
            "flex w-full items-center",
            "transition duration-240 ease-out",
            "backdrop-blur-2xl",
            "focus:outline-none focus-visible:shadow-brand-100",
            buttonClassName ?? ""
          ) + " focus-visible:shadow-innerBorder2" // hack for clsxm
        }
        disabled={disabled}
        tabIndex={tabIndex}
        onClick={handleOnClick}
      >
        {showProgress && (
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
            <div className="h-5 w-5 animate-spin">{progressSvg}</div>
          </div>
        )}

        {icon ? (
          <span
            className={clsxm(
              "h-6",
              children && "absolute",
              showProgress && "invisible"
            )}
          >
            {icon}
          </span>
        ) : null}

        {children ? (
          <span
            className={clsxm(
              textClasses,
              "inline-block",
              icon && "ml-8",
              showProgress && "invisible"
            )}
          >
            {children}
          </span>
        ) : null}
      </button>
    );

    const containerClassName = clsxm("inline-block", className);

    return href && !disabled ? (
      <Link
        href={href}
        className={containerClassName}
        target={target}
        rel={rel}
      >
        {button}
      </Link>
    ) : (
      <div className={containerClassName}>{button}</div>
    );
  }
);

export default Button;

// LEADS FULL [id: 346260752] - Leads + Clients 2022
export const MONDAY_FULL_LEADS = {
  ID: 346260752,
  COLS: {
    NAME: "name", // Name
    FIRST_NAME: "_____8", // Client.FirstName
    LAST_NAME: "_____3", // Client.LastName
    PAID_LOCK: "check", // Paid
    PAID: "dup__of_paid", // Paid?
    STATUS: "status", // Status
    CLIENT_ID: "item_id", // Clients ID
    CV: "link4", // CV
    EMAIL: "_________", // Client.Email
  },
};

// LEADS SELF [id: 4080729698] - Leads (Self-Servise)
export const MONDAY_SELF_LEADS = {
  ID: 4080729698,
  COLS: {
    NAME: "name", // Name
    FIRST_NAME: "_____8", // Client.FirstName
    LAST_NAME: "_____3", // Client.LastName
    PAID_LOCK: "check", // Paid
    PAID: "dup__of_paid", // Paid?
    STATUS: "status", // Status
    CLIENT_ID: "item_id", // Clients ID
    CV: "link4", // CV
    EMAIL: "_________", // Client.Email
    RESIDENCE: "text", // Residence
  },
};

// CLIENTS SELF [id: 4019870448] - Flow Exceptional Talent (Self Service)
export const MONDAY_SELF_CLIENTS = {
  ID: 4019870448,
  COLS: {
    CLIENT_ID: "text8", // Client ID
    STATUS: "text7", // Status
    EXTRA: "text", // Extra Info
    PROFILE: "______", // Profile
    ELIGIBILITY: "status_16", // Eligibility
  },
};

// CLIENTS FULL [id: 704551567] - Flow Exceptional Talent
export const MONDAY_FULL_CLIENTS = {
  ID: 704551567,
  COLS: {
    CLIENT_ID: "text8", // Client ID
    STATUS: "text7", // Status
    EXTRA: "text", // Extra Info
    PROFILE: "______", // Profile
    ELIGIBILITY: "status_16", // Eligibility
  },
  ELIGIBILITY_TEST_STATUS_INDEX: 4,
};

// FLOW FULL [id: 2729225139] - Clients flow (data)
// Additional Board with extra info such as Email, Google Drive, etc.
export const MONDAY_FULL_FLOW = {
  ID: 2729225139,
  COLS: {
    CLIENT_ID: "text", // Client ID
    GOOGLE_SHARED_DIR: "link9", // Immigram Shared
    GOOGLE_PLATFORM_DIR: "link8", // Platform Docs
  },
};

// FLOW SELF [id: 4105669711] - Clients flow (data Self-service)
// Additional Board with extra info such as Email, Google Drive, etc.
export const MONDAY_SELF_FLOW = {
  ID: 4105669711,
  COLS: {
    CLIENT_ID: "text", // Client ID
    GOOGLE_SHARED_DIR: "link", // Clients folder
    EMAIL: "email", // Email
  },
};

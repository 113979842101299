import { User } from "@prisma/client";
import { createContext } from "react";

export interface IUserContext {
  ownUser: User | null;
  applicationUser: User | null;
}

export const UserContext = createContext<IUserContext>({
  ownUser: null,
  applicationUser: null,
});

import { ButtonProps } from "immigram-ui-cmon/src/components/Button/Button";
import { ReactNode } from "react";
import { writable } from "ssws";
import { useStore } from "ssws/react";

import { ConfirmModalIconType } from "@/components/Modal/ConfirmModal";

type FuncWithUnnecessaryArg =
  | (() => Promise<void> | void)
  | (({ closeModal }: { closeModal: () => void }) => Promise<void> | void);

interface ModalControllerParams {
  gifUrl?: string;
  gifBgPosition?: string;
  iconType?: ConfirmModalIconType;
  heading: ReactNode;
  text: ReactNode;
  confirmUrl?: string;
  confirmButtonText?: ReactNode;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  cancelButtonText?: ReactNode | null;
  onConfirm?: FuncWithUnnecessaryArg;
  onCancel?: FuncWithUnnecessaryArg;
}
interface ModalControllerState extends ModalControllerParams {
  resolveModal: (isSubmitted: boolean) => void;
  hideModal: () => void;
}

const $modalControllerState = writable<ModalControllerState | null>(null);

export function showConfirmModal(params: ModalControllerParams) {
  hideConfirmModal();

  const isOpenPromise = new Promise<boolean>((resolve) => {
    $modalControllerState.set({
      ...params,
      resolveModal: resolve,
      hideModal: () => $modalControllerState.set(null),
    });
  });

  return isOpenPromise;
}

export function hideConfirmModal() {
  if (!$modalControllerState.value) {
    return;
  }

  const { resolveModal } = $modalControllerState.value;

  resolveModal(false);

  $modalControllerState.set(null);
}

export function useModalControllerState() {
  return useStore($modalControllerState);
}

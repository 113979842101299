import { MouseEvent } from "react";

export * from "./BaseIcon";
export * from "./Icon4Pointer";
export * from "./Icon16ChevronLeft";
export * from "./Icon16HelpInfo";
export * from "./Icon20DocDownloaded";
export * from "./Icon24Airplane";
export * from "./Icon24Award";
export * from "./Icon24Bell";
export * from "./Icon24Book";
export * from "./Icon24Cancel";
export * from "./Icon24Carousel";
export * from "./Icon24Cash";
export * from "./Icon24Change";
export * from "./Icon24Chat";
export * from "./Icon24ChatMessage";
export * from "./Icon24ChatMessageText";
export * from "./Icon24ChatMessageV2";
export * from "./Icon24Check";
export * from "./Icon24Checked";
export * from "./Icon24CheckOutline";
export * from "./Icon24ChevronDown";
export * from "./Icon24ChevronLeft";
export * from "./Icon24ChevronRight";
export * from "./Icon24ChevronUp";
export * from "./Icon24CirclePentool";
export * from "./Icon24Clock";
export * from "./Icon24CopyPaste";
export * from "./Icon24Delete";
export * from "./Icon24Doc";
export * from "./Icon24DocAttach";
export * from "./Icon24DocCheck";
export * from "./Icon24DocDocx";
export * from "./Icon24DocDownload";
export * from "./Icon24DocUpload";
export * from "./Icon24Earth";
export * from "./Icon24Edit";
export * from "./Icon24Education";
export * from "./Icon24Email";
export * from "./Icon24FileFolder";
export * from "./Icon24Flame";
export * from "./Icon24Github";
export * from "./Icon24GoogleDrive";
export * from "./Icon24Grid";
export * from "./Icon24Hamburger";
export * from "./Icon24HelpInfo";
export * from "./Icon24HelpInfoBold";
export * from "./Icon24Home";
export * from "./Icon24HomeBold";
export * from "./Icon24IdCard";
export * from "./Icon24IdCardBold";
export * from "./Icon24Job";
export * from "./Icon24LinkOut";
export * from "./Icon24Lock";
export * from "./Icon24Map";
export * from "./Icon24Menu";
export * from "./Icon24Mic";
export * from "./Icon24Money";
export * from "./Icon24Notification";
export * from "./Icon24Patent";
export * from "./Icon24Pause";
export * from "./Icon24Pen";
export * from "./Icon24People";
export * from "./Icon24PeopleB";
export * from "./Icon24Plus";
export * from "./Icon24Presentation";
export * from "./Icon24SelectPicker";
export * from "./Icon24Settings";
export * from "./Icon24SettingsBold";
export * from "./Icon24SignOut";
export * from "./Icon24Suit";
export * from "./Icon24Trash";
export * from "./Icon24Unchecked";
export * from "./Icon24Unlock";
export * from "./Icon24User";
export * from "./Icon24View";
export * from "./Icon44Cancel";
export * from "./Icon44HelpInfo";
export * from "./Icon44NoConnection";
export * from "./Icon44ScientificWork";
export * from "./Icon44Warning";

export interface IconProps {
  className?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

import { Prisma } from "@prisma/client";

import { prisma } from "@/lib/prisma";

export async function getProjectsByApplicationId(applicationId: string) {
  return await prisma.applicantProject.findMany({
    where: {
      applicantCompany: {
        applicant: {
          Application: {
            every: {
              id: applicationId,
            },
          },
        },
      },
    },
    include: {
      applicantCompany: {
        include: {
          careerRole: true,
        },
      },
      projectRole: true,
      // projectMetrics: true,
    },
    orderBy: { companySortOrder: Prisma.SortOrder.asc },
  });
}

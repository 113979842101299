import Button, {
  ButtonProps,
  ButtonType,
} from "immigram-ui-cmon/src/components/Button/Button";
import Heading, {
  HeadingTag,
} from "immigram-ui-cmon/src/components/Heading/Heading";
import {
  Icon24Check,
  Icon44Cancel,
  Icon44Warning,
} from "immigram-ui-cmon/src/components/Icons";
import Modal, {
  ModalOverlayStyle,
} from "immigram-ui-cmon/src/components/Modal/Modal";
import Paragraph from "immigram-ui-cmon/src/components/Paragraph/Paragraph";
import clsxm from "immigram-ui-cmon/src/lib/clsxm";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { MouseEvent, ReactNode } from "react";

export enum ConfirmModalIconType {
  Warning = "warning",
  Error = "error",
  Success = "success",
}

interface ConfirmModalProps {
  gifUrl?: string;
  gifBgPosition?: string;
  iconType?: ConfirmModalIconType;
  overlayStyle?: ModalOverlayStyle;
  icon?: ReactNode;
  heading: ReactNode;
  text: ReactNode;
  open: boolean;
  confirmUrl?: string;
  confirmButtonText?: ReactNode;
  confirmButtonProps?: ButtonProps;
  cancelButtonText?: ReactNode | null;
  cancelButtonProps?: ButtonProps;
  onCollapse?: () => void;
  onConfirmClick?: (event: MouseEvent) => void;
}

const ConfirmModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation();

  const { iconType = ConfirmModalIconType.Warning } = props;

  const confirmButtom = (
    <Button
      onClick={(event: MouseEvent) => {
        if (props.onConfirmClick) {
          event.preventDefault();
          props.onConfirmClick(event);
        }
      }}
      {...props.confirmButtonProps}
    >
      {props.confirmButtonText ?? t("application:confirmModal.confirm")}
    </Button>
  );

  return (
    <Modal
      isOpened={props.open}
      panelClassName={clsxm("w-full")}
      overlayStyle={props.overlayStyle}
      onClose={props.onCollapse}
    >
      <div
        className={clsxm(
          "group relative z-10 flex w-full flex-col items-center overflow-hidden rounded-2xl bg-white-900 p-8 text-center shadow-sm transition-all"
        )}
      >
        {props.gifUrl !== undefined ? (
          <div
            className="h-[172px] w-[172px] overflow-hidden rounded-full bg-gray-150 bg-cover"
            style={{
              backgroundImage: `url(${props.gifUrl})`,
              backgroundPosition:
                props.gifBgPosition !== undefined ? props.gifBgPosition : "0 0",
            }}
          />
        ) : (
          <>
            {iconType === ConfirmModalIconType.Warning && (
              <div className="flex h-24 w-24 items-center justify-center rounded-[2rem] bg-yellow-50a text-yellow-800">
                {props.icon || <Icon44Warning />}
              </div>
            )}
            {iconType === ConfirmModalIconType.Error && (
              <div className="flex h-24 w-24 items-center justify-center rounded-[2rem] bg-red-50a text-red-800">
                {props.icon || <Icon44Cancel />}
              </div>
            )}
            {iconType === ConfirmModalIconType.Success && (
              <div className="flex h-24 w-24 items-center justify-center rounded-[2rem] bg-green-50 text-green-800">
                <Icon24Check className="text-6xl" />
              </div>
            )}
          </>
        )}

        <Heading tag={HeadingTag.H4} className="mt-5 mb-3">
          {props.heading}
        </Heading>

        <Paragraph className="mb-6">{props.text}</Paragraph>

        <div className="flex justify-center gap-x-2">
          {props.confirmUrl ? (
            <Link href={props.confirmUrl} passHref={true}>
              {confirmButtom}
            </Link>
          ) : (
            confirmButtom
          )}
          {props.cancelButtonText !== null && (
            <Button
              type={ButtonType.Secondary}
              onClick={props.onCollapse}
              {...props.cancelButtonProps}
            >
              {props.cancelButtonText ?? t("application:confirmModal.cancel")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

/* eslint-disable @typescript-eslint/no-var-requires */
const I18NextHttpBackend = require("i18next-http-backend");

const EXP_TIME = 1000 * 60 * 10; // 10 minutes

module.exports = {
  debug: false,
  backend: {
    loadPath: `${process.env.NEXT_PUBLIC_SIMPLELOCALIZE_CDN_URL}/{{lng}}/{{ns}}`,
    reloadInterval: EXP_TIME,
  },
  i18n: {
    defaultLocale: "en",
    locales: ["en", "ru"],
  },
  defaultNS: "application",
  ns: ["application"],
  serializeConfig: false,
  use: [I18NextHttpBackend],
};

import { toast, ToastContainer } from "react-toastify";

const MAX_TIME_TO_SHOW_TOAST = 7000;

export type NotificationProps = {
  variant: "success" | "warning" | "alert" | "neutral";
  title: string;
  description?: string;
};
export const Notification = () => {
  return <ToastContainer />;
};

const CloseButton = ({
  closeToast,
}: {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
  return (
    <span className="flex items-center pr-2" onClick={closeToast}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M10.5368 11.7489C10.8715 12.0837 11.4142 12.0837 11.7489 11.7489C12.0837 11.4142 12.0837 10.8715 11.7489 10.5368L7.21218 6L11.7489 1.46323C12.0837 1.1285 12.0837 0.585787 11.7489 0.251051C11.4142 -0.0836837 10.8715 -0.0836837 10.5368 0.251051L6 4.78782L1.46323 0.251051C1.1285 -0.0836838 0.585786 -0.0836837 0.251052 0.251051C-0.0836839 0.585787 -0.0836839 1.1285 0.251052 1.46323L4.78782 6L0.251052 10.5368C-0.0836834 10.8715 -0.0836834 11.4142 0.251052 11.7489C0.585787 12.0837 1.1285 12.0837 1.46323 11.7489L6 7.21218L10.5368 11.7489Z"
          fill="#242424"
          fillOpacity="0.32"
        />
      </svg>
    </span>
  );
};

export const showNotification = (props: NotificationProps) => {
  const { variant, description, title } = props;

  const baseProps = {
    hideProgressBar: true,
    autoClose: MAX_TIME_TO_SHOW_TOAST,
    closeButton: CloseButton,
  };

  const content = (
    <div className="flex flex-col">
      <p className="text-base text-black-900">{title}</p>
      {!!description && (
        <p className="text-smb font-normal text-black-600">{description}</p>
      )}
    </div>
  );
  if (variant === "success") {
    toast.success(content, {
      ...baseProps,
      className: "notification success",
      icon: (
        <div className="flex h-8 w-8 items-center justify-center rounded-xl bg-green-50a text-green-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
          >
            <path
              d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L5 7.58579L1.70711 4.29289C1.31658 3.90237 0.683417 3.90237 0.292893 4.29289C-0.0976311 4.68342 -0.0976311 5.31658 0.292893 5.70711L4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711L13.7071 1.70711Z"
              fill="currentColor"
            />
          </svg>
        </div>
      ),
    });
  }

  if (variant === "alert") {
    toast.error(content, {
      ...baseProps,
      className: "notification alert",
      icon: (
        <div className="flex h-8 w-8 items-center justify-center rounded-xl bg-red-50a text-red-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M10.5368 11.7489C10.8715 12.0837 11.4142 12.0837 11.7489 11.7489C12.0837 11.4142 12.0837 10.8715 11.7489 10.5368L7.21218 6L11.7489 1.46323C12.0837 1.1285 12.0837 0.585787 11.7489 0.251051C11.4142 -0.0836837 10.8715 -0.0836837 10.5368 0.251051L6 4.78782L1.46323 0.251051C1.1285 -0.0836838 0.585786 -0.0836837 0.251052 0.251051C-0.0836839 0.585787 -0.0836839 1.1285 0.251052 1.46323L4.78782 6L0.251052 10.5368C-0.0836834 10.8715 -0.0836834 11.4142 0.251052 11.7489C0.585787 12.0837 1.1285 12.0837 1.46323 11.7489L6 7.21218L10.5368 11.7489Z"
              fill="currentColor"
            />
          </svg>
        </div>
      ),
    });
  }

  if (variant === "warning") {
    toast.warning(content, {
      ...baseProps,
      className: "notification warning",
      icon: (
        <div className="flex h-8 w-8 items-center justify-center rounded-xl bg-yellow-50a text-yellow-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M9 10C8.44771 10 8 9.55228 8 9L8 7C8 6.44772 8.44772 6 9 6C9.55229 6 10 6.44772 10 7V9C10 9.55229 9.55229 10 9 10Z"
              fill="currentColor"
            />
            <path
              d="M8 13C8 12.4477 8.44772 12 9 12C9.55229 12 10 12.4477 10 13C10 13.5523 9.55229 14 9 14C8.44771 14 8 13.5523 8 13Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.99992 0C8.08348 0 7.24164 0.505101 6.81038 1.31373L0.611639 12.9364C0.167099 13.7699 0.0845218 14.7494 0.383245 15.6456C0.851923 17.0516 2.16773 18 3.64982 18H14.35C15.8321 18 17.1479 17.0516 17.6166 15.6456C17.9153 14.7494 17.8327 13.7699 17.3882 12.9364L11.1895 1.31373C10.7582 0.505102 9.91636 0 8.99992 0ZM8.57508 2.2549C8.65876 2.098 8.8221 2 8.99992 2C9.17774 2 9.34108 2.098 9.42476 2.2549L15.6235 13.8775C15.8098 14.2269 15.8444 14.6375 15.7192 15.0131C15.5228 15.6025 14.9712 16 14.35 16H3.64982C3.02859 16 2.47706 15.6025 2.28061 15.0131C2.1554 14.6375 2.19001 14.2269 2.37634 13.8775L8.57508 2.2549Z"
              fill="currentColor"
            />
          </svg>
        </div>
      ),
    });
  }

  if (variant === "neutral") {
    toast.info(content, {
      ...baseProps,
      className: "notification neutral",
      icon: (
        <div className="bg-gray-50a flex h-8 w-8 items-center justify-center rounded-xl text-gray-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M6 7.60555C6 6.26814 6.6684 5.01921 7.7812 4.27735C9.1248 3.38162 10.8752 3.38162 12.2188 4.27735L12.3944 4.39445C13.3975 5.06316 14 6.18892 14 7.39445C14 9.17938 12.703 10.6614 11 10.9494V12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12V10.2727C9 9.56982 9.56982 9 10.2727 9H10.3944C11.2812 9 12 8.28117 12 7.39445C12 6.85763 11.7317 6.35633 11.285 6.05855L11.1094 5.94145C10.4376 5.49359 9.5624 5.49359 8.8906 5.94145C8.3342 6.31238 8 6.93685 8 7.60555V8C8 8.55229 7.55228 9 7 9C6.44772 9 6 8.55229 6 8V7.60555Z"
              fill="currentColor"
            />
            <path
              d="M11 15C11 15.5523 10.5523 16 10 16C9.44771 16 9 15.5523 9 15C9 14.4477 9.44771 14 10 14C10.5523 14 11 14.4477 11 15Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
              fill="currentColor"
            />
          </svg>
        </div>
      ),
    });
  }
};

export default Notification;
